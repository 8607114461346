import { Button as MuiButton, type ButtonProps as MuiButtonProps } from "@mui/material";

import type { ButtonSize, ButtonVariant } from "../theming";
import { ButtonInternalLink } from "./ButtonInternalLink";
import { CbhIcon, type IconType } from "./CbhIcon";

export interface ButtonProps
  extends Omit<
    MuiButtonProps,
    "variant" | "invert" | "color" | "startIcon" | "endIcon" | "outlineThickness"
  > {
  endIconType?: IconType;
  invert?: boolean;
  size?: ButtonSize;
  startIconType?: IconType;
  variant?: ButtonVariant;
  /**
   * Sets aria-selected to true. This is useful when you need to force visual active state
   * We do this for some flows that buttons look selected when some options were picked by user
   * (e.g. when user sets a filter in shift discovery, the button in header looks selected)
   */
  isSelected?: boolean;
}

const buttonVariantOverrides: Record<ButtonVariant, MuiButtonProps["variant"]> = {
  contained: "contained",
  outlined: "outlined",
  destructive: "outlined",
  outlinedHeavy: "outlined",
};

const buttonOutlineThicknessOverrides: Record<ButtonVariant, MuiButtonProps["outlineThickness"]> = {
  contained: "regular",
  outlined: "regular",
  destructive: "regular",
  outlinedHeavy: "thick",
};

/**
 * A wrapper around MuiButton that limits variants to our custom ones and adds our custom icon handling
 * NOTE: Provide `href` to make the button render as an anchor tag. It will support react-router redirecs
 */
export function Button(props: ButtonProps) {
  const {
    variant = "contained",
    startIconType,
    endIconType,
    invert,
    "aria-selected": ariaSelected,
    isSelected,
    ...restProps
  } = props;

  return (
    <MuiButton
      {...restProps}
      outlineThickness={buttonOutlineThicknessOverrides[variant]}
      invert={invert ? "true" : undefined}
      aria-selected={ariaSelected ?? isSelected}
      variant={buttonVariantOverrides[variant]}
      color={variant === "destructive" ? "error" : "primary"}
      startIcon={startIconType ? <CbhIcon type={startIconType} /> : undefined}
      endIcon={endIconType ? <CbhIcon type={endIconType} /> : undefined}
      LinkComponent={ButtonInternalLink}
    />
  );
}
