import { mergeSxProps } from "@clipboard-health/ui-react";
import { type SxProps, type Theme } from "@mui/material";
import { type ReactNode } from "react";

import { HorizontalScrollView } from "./HorizontalScrollView";

interface CalloutsWrapperProps {
  children: ReactNode;
  sx?: SxProps<Theme>;
}

export function CalloutsWrapper(props: CalloutsWrapperProps) {
  const { children, sx } = props;

  return (
    <HorizontalScrollView
      stretch
      sx={mergeSxProps(
        {
          "& > *": {
            width: "fit-content",
            minWidth: "auto",
          },

          // If there are more than two callouts, let them take up the width they need.
          // We're setting min of 35% to make sure that they fill the whole width of the screen
          // and indicate there is more content to scroll
          "&:has(> :nth-child(3))": {
            "> *": {
              minWidth: "35%",
            },
          },

          // If there are exactly two callouts, display them side by side
          // filling the available space
          "&:has(> :nth-child(2):last-child) > *": {
            width: "fit-content",
            minWidth: "50%",
          },
        },
        sx
      )}
    >
      {children}
    </HorizontalScrollView>
  );
}
