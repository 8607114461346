import { mergeSxProps, Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { ButtonBase, type ButtonBaseProps, Stack } from "@mui/material";

import { CallToActionIcon } from "./CallToActionIcon";
import { CbhIcon, type IconType } from "./CbhIcon";

export interface CalloutProps extends Omit<ButtonBaseProps, "title"> {
  title: string | React.ReactNode;
  iconType?: IconType;
  text?: string;
}

export function Callout(props: CalloutProps) {
  const { title, iconType, text, sx, ...restProps } = props;

  return (
    <ButtonBase
      sx={mergeSxProps(
        (theme) => ({
          backgroundColor: theme.palette.intent?.neutral.background,
          color: theme.palette.intent?.neutral.text,
          borderRadius: theme.borderRadius?.xLarge,
          py: 2,
          paddingLeft: 4,
          paddingRight: 3,
          gap: 3,
          userSelect: "none",
          width: "100%",
          alignItems: "center",

          "& svg": {
            flexShrink: 0,
          },

          "& .MuiTypography-root": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          },
        }),
        sx
      )}
      {...restProps}
    >
      {iconType && <CbhIcon type={iconType} size="medium" />}

      <Stack
        direction="row"
        spacing={3}
        alignItems="baseline"
        sx={{ overflow: "hidden", marginRight: "auto" }}
      >
        <Text
          semibold
          variant="body1"
          sx={{
            flexShrink: 0,
            color: "inherit",
            // Increased line height to avoid cutting off letters at the bottom.
            // Cut off is caused by the truncation of the text.
            lineHeight: 1.125, // 18px
          }}
        >
          {title}
        </Text>

        {isDefined(text) && (
          <Text variant="body2" sx={{ color: "inherit" }}>
            {text}
          </Text>
        )}
      </Stack>

      <CallToActionIcon />
    </ButtonBase>
  );
}
