import { mergeSxProps } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";

import { type IntentVariant } from "../theming";
import { Card, type CardProps } from "./Card";

export interface CardWithIntentProps extends Omit<CardProps, "outlined"> {
  intent?: IntentVariant;
}

export function CardWithIntent(props: CardWithIntentProps) {
  const { intent, sx, ...restProps } = props;

  return (
    <Card
      sx={mergeSxProps(
        {
          ...(isDefined(intent) && {
            border: (theme) => `1px solid ${String(theme.palette.intent?.[intent]?.border)}`,
          }),
        },
        sx
      )}
      {...restProps}
    />
  );
}
