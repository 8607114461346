import { Badge as MuiBadge, type BadgeProps as MuiBadgeProps } from "@mui/material";

import type { BadgeSize } from "../theming";

interface BadgeProps
  extends Pick<
    MuiBadgeProps,
    "color" | "invisible" | "max" | "showZero" | "variant" | "overlap" | "anchorOrigin" | "sx"
  > {
  count: number;
  children?: React.ReactNode;
  size?: BadgeSize;
}

export function Badge(props: BadgeProps) {
  const { color = "primary", count, ...rest } = props;

  return <MuiBadge {...rest} color={color} badgeContent={count} />;
}
