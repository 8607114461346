import { Text, Title } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { type ReactNode } from "react";

import { Illustration, type IllustrationType } from "./Illustration";

export interface BottomSheetIllustrationContentProps {
  illustrationType: IllustrationType;
  title: ReactNode;
  description?: ReactNode;
}

export function BottomSheetIllustrationContent(props: BottomSheetIllustrationContentProps) {
  const { illustrationType, title, description } = props;

  return (
    <Stack spacing={11} sx={{ paddingTop: 11, paddingX: 5 }}>
      <Stack spacing={7} justifyContent="center" alignItems="center" sx={{ textAlign: "center" }}>
        <Illustration type={illustrationType} />
        <Title component="h2" sx={{ textWrap: "balance" }}>
          {title}
        </Title>
        {description && (
          <Text semibold variant="body1" color={(theme) => theme.palette.text.secondary}>
            {description}
          </Text>
        )}
      </Stack>
    </Stack>
  );
}
