import { Box, type SxProps, type Theme } from "@mui/material";

interface BlurProps {
  sx?: SxProps<Theme>;
}

export function Blur(props: BlurProps) {
  const { sx } = props;

  return (
    <Box
      sx={{
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        borderRadius: (theme) => theme.borderRadius?.xSmall,
        filter: "blur(4px)",
        ...sx,
      }}
    />
  );
}
