import { Text, Title } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { type ReactNode } from "react";

import { LoadingSpinner } from "./LoadingSpinner";

export interface BottomSheetLoadingContentProps {
  title: ReactNode;
  description: ReactNode;
}

/**
 * This component is used to display a loading state in a BottomSheet component,
 * when we need to wait for an async operation to complete between BottomSheet
 * content state transitions.
 *
 * It contains a loading spinner, a title and a description.
 *
 * An example of usage is:
 * 1. Show a BottomSheet which uploads a file.
 * 2. On upload start, show this component in the BottomSheet.
 * 3. On upload complete, show a BottomSheet content with a success or an error message.
 */
export function BottomSheetLoadingContent(props: BottomSheetLoadingContentProps) {
  const { title, description } = props;

  return (
    <Stack spacing={9} justifyContent="center" alignItems="center" sx={{ paddingY: 11 }}>
      <LoadingSpinner size="xlarge" variant="secondary" />
      <Stack spacing={7} justifyContent="center" alignItems="center" sx={{ textAlign: "center" }}>
        <Title component="h2" sx={{ textWrap: "balance" }}>
          {title}
        </Title>
        <Text variant="body1" color={(theme) => theme.palette.text.secondary}>
          {description}
        </Text>
      </Stack>
    </Stack>
  );
}
